import React, { useState, useCallback } from "react"
import { useNavigate, Link } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import useLoginStore from "src/store/LoginStore"
import useDashboardStore from "src/store/DashBoardStore"
import { useSvcList } from "src/api/SvcApi"
import { useBookmarkCount } from "src/api/VocaApi"
import "react-tabs/style/react-tabs.css"
import useUtils from "src/common/useUtils"
import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"
import CardModal from "src/components/layouts/CardModal"

import Loading from "src/components/comm/Loading"
import avt from "src/assets/images/avatar/avt-author-tab.jpg"

const Dashboard = () => {
  const navigate = useNavigate()
  const { comma } = useUtils()
  const { login } = useLoginStore()
  const { choiceItem, setChoiceItem } = useDashboardStore()
  const { data: bookmarkCount, isLoading: bookmarkCountLoading } = useBookmarkCount(login?.mem_id)
  const { data: svcList, isLoading } = useSvcList(login, "Y")
  const [menuTab] = useState([
    {
      class: "active",
      name: ""
    }
  ])
  const [panelTab] = useState([
    {
      id: 1,
      dataContent: []
    }
  ])

  const [visible, setVisible] = useState(8)
  const [modalShow, setModalShow] = useState(false)

  const clickMyVoca = useCallback((chiceItem) => {
    setChoiceItem("voca")
    navigate("/word/mynotes")
  }, [])

  const clickMySent = useCallback((chiceItem) => {
    setChoiceItem("sent")
    navigate("/word/mynotes")
  }, [])

  if (isLoading || bookmarkCountLoading) {
    return <Loading />
  }

  return (
    <div className="authors-2">
      <Header />
      <section className="tf-section authors" style={{ paddingTop: "100px" }}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12 col-12">test</div>
            <div className="col-xl-9 col-lg-9 col-md-12 col-12">
              <div className="flat-tabs tab-authors">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-lg-padding-right-0">
                    <div className="author-profile flex d-lg-radius-right-0">
                      <div className="feature-profile" style={{ paddingTop: "10px" }}>
                        <img src={avt} alt="Axies" className="avatar" style={{ width: "125px" }} />
                      </div>
                      <div className="infor-profile">
                        <h2 className="title" style={{ fontSize: "2.6em", marginLeft: "10px" }}>
                          {login?.user_name} {/*} - <span style={{ fontSize: "0.6em" }}>골드레벨</span>*/}
                        </h2>
                        <p className="content" style={{ margin: "7px 0 0 10px" }}>
                          푼퀴즈
                        </p>
                        <p className="content" style={{ margin: "7px 0 0 10px" }}>
                          정답률
                        </p>
                      </div>
                      <div className="widget-social style-3" style={{ marginTop: "-20px" }}>
                        <div className="form-inner">
                          <button
                            onClick={() => clickMyVoca()}
                            className="submit"
                            style={{ padding: "15px 15px", borderRadius: "16px", textAlign: "center" }}
                          >
                            <span style={{ fontSize: "1em" }}>내단어</span>
                            <span
                              style={{
                                color: "orange",
                                fontSize: "1.5em",
                                display: "inline-block",
                                verticalAlign: "middle",
                                margin: "-6px 0 0 5px"
                              }}
                            >
                              {bookmarkCount.voca_count}
                            </span>
                          </button>

                          <Link to="/word/mynotes">
                            <button
                              onClick={() => clickMySent()}
                              className="submit"
                              style={{ padding: "15px 15px", borderRadius: "16px", marginTop: "10px", textAlign: "center" }}
                            >
                              <span style={{ fontSize: "1em" }}>내문장</span>
                              <span
                                style={{
                                  color: "orange",
                                  fontSize: "1.5em",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  margin: "-6px 0 0 5px"
                                }}
                              >
                                {bookmarkCount.sent_count}
                              </span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 d-xl-block col-lg-6 d-lg-block d-lg-padding-left-0">
                    <div className="author-profile flex d-lg-radius-left-0 d-sm-radius-0" style={{ height: "100%" }}>
                      <div>공지사항</div>
                      <div>스터디랭킹</div>
                    </div>
                  </div>
                </div>
                <Tabs>
                  <TabList style={{ display: "block" }}>
                    {menuTab.map((item, index) => (
                      <Tab key={index}>{item.name}</Tab>
                    ))}
                  </TabList>
                  <div className="content-tab" style={{ marginTop: "16px" }}>
                    <div className="content-inner">
                      <div className="row">
                        {panelTab.map((item, index) => (
                          <TabPanel key={index}>
                            <div className="myCard">
                              <div className="container">
                                {svcList.map((data, index) => (
                                  <div key={index} className="card col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="box">
                                      <div className="content">
                                        <div className="ag-courses-item_bg"></div>
                                        <h2>{data.etc}</h2>
                                        <h3 style={{ fontSize: "2.4em" }}>{data.svc_nm}</h3>
                                        <p>단어 1200</p>
                                        <p>패턴 300</p>
                                        <p>문장 3000</p>
                                        <Link to={`/word/gate-list?svc_id=${data.svc_id}`}>시작하기</Link>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </TabPanel>
                        ))}
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
      <Footer />
    </div>
  )
}

export default Dashboard
